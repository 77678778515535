import React, { useState } from 'react';

import {Editor, EditorState, convertFromRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import '../../consents/consentStyle.css';
// import CataractAddOns from './CataractAddOns'

// Get rid of all the functionality and make read only with addition of replacing props.  Add readOnly to editor

export default function BedsheetReader (props) {

  
  const str = props.bedsheetData

  // console.log(str)

  //=====================================================================
  let lensTarget = (props.lensTargetNear === true)
    ? 'near'
    : 'distance'

  //=====================================================================
  let lensAndTarget = (props.lensOption === "Cataract vision")
  ? "____________"
  : (props.lensCorrectsNearVision === "Yes" ||
    props.lensCorrectsIntermediateVision === "Yes")
    ? `${props.lensModel}: ________`
    : props.lensTargetNear === true
      ? `${props.lensModel}: ________ (near)`
      : `${props.lensModel}: ________ (distance)`

  //=====================================================================
  let nearTargetMrx = (props.lensTargetNear === true)
    ? 'Target -2.00 for near'
    : ''

  //=====================================================================
  let sulcusLens = (props.sulcusLens)
    ? `${props.sulcusLens}:________`
    : ''

  //=====================================================================
  let ptAllergies = props.allergies

  let codeStatus = props.codeStatus

  //=====================================================================
  let toricAlignment = 
    (
      props.lensKAstigMin*1 === 0
      ||
      props.lensKAstigMin === 'dontuse'
    )
      ? ''
      : 'Align at ______ ° axis'
      
  //=====================================================================
    // console.log(lensTarget)

  // let cataractAddOn =  (props.side === 'RIGHT')
  //   ? props.cataractAddOn.map(m => (
  //       `${m.name} ${m.icd}1 ${m.cpt}`
  //      ))
  //   : props.cataractAddOn.map(m => (
  //       `${m.name} ${m.icd}2 ${m.cpt}`
  //     ))
    
  //=====================================================================
  let icdTen = (props.side === 'RIGHT')
    ? '1'
    : (props.side === 'LEFT')
      ? '2'
      : null

  //=====================================================================
  let dxNameAndCodes = (props.cataractAddOn.length > 0)
  ? (props.cataractAddOn)
      .filter(c => c.value === 'true')
      .map(c => `${c.name}${
        (props.flacs === true && c.name === "Cataract surgery")
          ? ', femtosecond laser'
          : ''
      }${
        (props.cri === true && c.name === "Cataract surgery")
          ? ', corneal relaxing incisions'
          : ''
      } (${c.icd}${icdTen}, ${c.cpt}) `)
  : []
  
  //=====================================================================
  let dxAddOns = 
    (props.cataractAddOn.length > 0) 
      ? props.cataractAddOn
        .filter(c => c.value === 'true')
        .map(c => 
          c.name !== 'Cataract surgery' 
            ? `with ${c.name}`
            : ''
        )
      :''
  
  
  //=====================================================================
  let procedureName = (props.cataractAddOn.length > 0)
  ? (props.cataractAddOn)
      .filter(c => c.value === 'true')
      .map(c => `${c.name}`)
  : []
  
  //=====================================================================  
  let ptContraEval = (props.contraindications.length > 0)
    ? (props.contraindications)
        .filter(c => c.value === 'true')
        .map(c => ` ${c.name}`)
    : []

  let ptContraindications = ptContraEval.length > 0
      ? `Hx of${ptContraEval}`
      : ''

  //=====================================================================
  let lensCriFee = (props.cri === true)
    ? props.lensCriFee
    : 0

  let lensFlacsFee = (props.flacs === true)
    ? props.lensFlacsFee
    : 0

  //=====================================================================

  // Use equipmentPreference for axis
  let equipmentPreferenceAxis =
  props.equipmentPreference === props.biometer
  ? props.biometerAxis
  : props.equipmentPreference === props.topographer
    ? props.topographerAxis
    : null

  //=====================================================================
  let ptRefraction = 
    (props.power !== '' && props.cylinder !== '' && props.axis !== '')
    ? `${props.power} ${props.cylinder} x${props.axis}`
    : (props.power !== '' && props.cylinder !== '' && props.axis === '')
      ? `${props.power} ${props.cylinder}`
      :`________________________  `

  let bioAstigAxis = (props.biometerAstig === '')
    ? ''
    : `${props.biometerAstig} D @ ${props.biometerAxis}`

  //=====================================================================
  let chordMu = `${props.chordMuTotal} mm`

  //=====================================================================
  let topoAstigAxis = (props.topographerAstig === '')
    ? ''
    : `${props.topographerAstig} D @ ${props.topographerAxis}`

  //=====================================================================
  let topoSphAb = props.topographerSphAb
  
  //=====================================================================
  let topoHoa = props.topographerHoa
  
  //=====================================================================
   //phakic power
   let p = (props.power.toLowerCase() === 'plano' ||
   props.power.toLowerCase() === 'pl' ||
   props.power === '')
     ? 0
     : props.power*1;
 
   //phakic cylinder
   let c = (props.cylinder.toLowerCase() === 'sphere' ||
     props.cylinder.toLowerCase() === 'sph' ||
     props.cylinder === '')
       ? 0
       : props.cylinder*0.5;
 
  //phakic spherical equivalent
   let se = (p*1+c*1)

  //math for distance trial frames
   let distanceTrialFrames = 
    //is this the planned IOL a spherical lens?
    (
      props.lensKAstigMin*1 === 0
      ||
      props.lensKAstigMin === 'dontuse'
    )
    ? se*1 === 0 //is adjusting power going to make it 'plano'
      ? `Plano sphere`
      : se*1 > 0 //have to do code below to get correct + sign
        ? `+${(Math.floor((se*1) * 4) / 4).toFixed(2)} sphere`
        : `${(Math.floor((se*1) * 4) / 4).toFixed(2)} sphere` //print spherical equivalent to simulate monofocal lens  
    : //does the manifest refraction have an axis (meaning also has cylinder)?  
      (
        props.power !== '' 
        && 
        props.cylinder !== '' 
        && 
        props.axis !== ''
      )
      ? `${props.power} ${props.cylinder} x${props.axis}` //print full Rx
      : //does the manifest refraction not have axis (meaning cylinder is sphere)
        (
          props.power !== '' 
          && 
          props.cylinder !== '' 
          && 
          props.axis === ''
        )
        ? `${props.power} ${props.cylinder}` //print Rx with sphere for cylinder
        : `` //I think leaving this blank might be problematic 


  // math for near trial frames
  let nearTrialFrames = 
    //is this the planned IOL a spherical lens?
    (
      props.lensKAstigMin*1 === 0
      ||
      props.lensKAstigMin === 'dontuse'
    )
    ? (se*1+2 === 0) //is adjusting spherical equivalent going to be 'plano'
      ? `Plano`
      : se*1+2 > 0 //have to do code below to get correct + sign
        ? `+${(Math.floor((se*1+2) * 4) / 4).toFixed(2)} sphere`
        : `${(Math.floor((se*1+2) * 4) / 4).toFixed(2)} sphere`
    :  
      //does the manifest refraction have an axis (meaning also has cylinder)? 
      (props.power !== '' && props.cylinder !== '' && props.axis !== '')
        ? 
          (p*1+2 === 0) //is adjusting power going to make it 'plano'
            ? `Plano ${props.cylinder} x${props.axis}`
            : p*1+2 > 0 //have to do code below to get correct + sign
              ? `+${(Math.floor((p*1+2) * 4) / 4).toFixed(2)} ${props.cylinder} x${props.axis}`
              : `${(Math.floor((p*1+2) * 4) / 4).toFixed(2)} ${props.cylinder} x${props.axis}`
            
        : (props.power !== '' && props.cylinder !== '' && props.axis === '') //does the manifest refraction not have axis (meaning cylinder is sphere)
          ? 
            p*1+2 === 0 //is adjusting power going to make it 'plano'
              ? `Plano ${props.cylinder}`
              : p*1+2 > 0 //have to do code below to get correct + sign
                ? `+${(Math.floor((p*1+2) * 4) / 4).toFixed(2)} ${props.cylinder}`
                : `${(Math.floor((p*1+2) * 4) / 4).toFixed(2)} ${props.cylinder}`
          :``


    //Was a toric lens offered? 
    // Step 1: Get astigmatic lenses
    const astigLenses = props.lensData?.filter(lens => {
      return props.ptLensList.includes(lens.lens_name) && Number(lens.lens_astig_min) > 0;
    });

    // Step 2: Is toric offered?
    const toricOffered = astigLenses?.length > 0;
    
    // Step 3: Was a toric lens not chosen?
    const toricNotChosen = props.lensKAstigMin * 1 === 0 || props.lensKAstigMin === 'dontuse';
    
    // Step 4: Average astigmatism and adjustment
    const topoAstig = Number(props.topographerAstig);
    const topoAxis = Number(props.topographerAxis).toFixed(0)
    const bioAstig = Number(props.biometerAstig);
    const avgAstig = (topoAstig + bioAstig) / 2;
    const minAstig = Math.min(topoAstig, bioAstig);
    const unCorrectedAstig = Math.floor((Number(c*2) + (avgAstig)) * 4) / 4
    const correctedAstig = Math.floor((c*2+ avgAstig-Math.min(topoAstig, bioAstig)) * 4) / 4
    const uncorrectedPower = (Math.floor((p-0.5*avgAstig) * 4) / 4).toFixed(2)
    const correctedPower = (Math.floor(p-0.5*(correctedAstig-(c*2)) * 4) / 4).toFixed(2)
    const extraAstig = unCorrectedAstig === 0 ? 'sphere' : `+${unCorrectedAstig.toFixed(2)}`
    const extraAxis = extraAstig === 'sphere' ? '' : `x${Number(topoAxis)}`;
    const residualAstig = correctedAstig === 0 ? 'sphere' : `+${correctedAstig.toFixed(2)}`
    const residualAxis = residualAstig === 'sphere' ? '' : `x${Number(props.axis)}`;
    const extraPower = Number(uncorrectedPower) === 0 
      ? 'Plano' 
      : Number(uncorrectedPower) > 0
      ? `+${uncorrectedPower}`
      : uncorrectedPower
    const residualPower = Number(correctedPower) === 0 
    ? 'Plano' 
    : Number(correctedPower) > 0
    ? `+${correctedPower}`
    : correctedPower


    // Step 5: Create trial frame Rx if applicable
    let trialRx = null;

    if (toricOffered && toricNotChosen) {
        trialRx = `Toric simulation using trial lenses and Pt prefers: ${extraPower} ${extraAstig} ${extraAxis} (monofocal);   ${residualPower} ${residualAstig} ${residualAxis} (toric)`;
      } else {
        // Could add fallback for average uncorrected astigmatism MRx if needed
        trialRx = null;
      }


    // else if (props.power !== '' && props.cylinder !== '' && props.axis === '') {
    //   trialRx = `Trial without ${extraPower} ${extraAstig} ${extraAxis} and with ${residualPower} ${residualAstig} ${residualAxis}astigmatism corrected`;
    // } 
// Use trialRx in your render or logic

    
    
    

    // let isNonPresbyopiaToric = (lens.corrects_intermediate_vision === 'No' && lens.corrects_near_vision === 'No') &&
    //     ((props.topographerAstig * 1 + props.biometerAstig * 1)/2) >= lens.lens_astig_min * 1
    //     &&
    //     (props.equipmentPreferenceAstig * 1 <= (lens.lens_astig_max * 1 + lens.lens_astig_residual * 1) || lens.lens_astig_residual === 'dontuse');
    //   if (lens.corrects_intermediate_vision === 'No' && lens.corrects_near_vision === 'No' && lens.lens_astig_min > 0) {
    //     if (!isNonPresbyopiaToric) {
    //       warnings.push(`The residual astigmatism is outside the range for this toric lens`);
    //     }
    //   }




  //warn when myope picks distance, give trial frame Rx
  let bewareOfMyope = 
    (     
      //is this a usefully myopic patient who wants a monofocal lens set for distance?           
      (se) <= -1.5
      &&
      (se) >= -3
      &&
      props.lensTargetNear === false
      &&
      props.lensCorrectsNearVision === "No"
    )
  ? props.lensCorrectsIntermediateVision === "Yes"
    ? 'Does patient want to target distance-intermediate or intermediate-near?'
    : `Which trial frames does patient prefer?      ${distanceTrialFrames} (distance) or ${nearTrialFrames} (near)`
  : ''
  
  //=====================================================================
  let lensesOffered = 
    props.ptLensList &&
    props.ptLensList.map(m => (
      "  "+m
    )) 

  // let mapLenses = 
  //   props.ptLensList &&
  //   props.ptLensList.map((m, i) => (
  //     m === props.lensOption
  //     ? lensesOffered
  //         {m} (Patient's selection)
  //       </span>
  //     : 
  //       <span
  //           style={{
  //             margin: '0 0 0 10px', textDecorationLine: 'line-through'}}
  //         >
  //           {m} 
  //       </span>

  //=====================================================================
  // let lasikAscrs = contraArray.find(c => c ==="lasik or prk")
  //   ?
  //     <div>
  //       <p style={{margin: '0'}}>Hx of LASIK or PRK</p>
  //       <p style={{margin: '0 0 0 10px'}}>Atlas 0 mm: </p>
  //       <p style={{margin: '0 0 0 10px'}}>Atlas 1 mm: </p>
  //       <p style={{margin: '0 0 0 10px'}}>Atlas 2 mm: </p>
  //       <p style={{margin: '0 0 0 10px'}}>Atlas 3 mm: </p>
  //       <p style={{margin: '0 0 0 10px'}}>Atlas 4 mm: </p>
  //       <p style={{margin: '0 0 0 10px'}}>Pentacam TNP 4mm zone: </p>
  //     </div>
  //   : ''
  
  //=====================================================================
  // let rkAscrs = contraArray.find(c => c ==="radial keratotomy")
  // ?
  //   <div>
  //     <p style={{margin: '0'}}>Hx of RK</p>
  //     <p style={{margin: '0 0 0 10px'}}>Atlas 1 mm: </p>
  //     <p style={{margin: '0 0 0 10px'}}>Atlas 2 mm: </p>
  //     <p style={{margin: '0 0 0 10px'}}>Atlas 3 mm: </p>
  //     <p style={{margin: '0 0 0 10px'}}>Atlas 4 mm: </p>
  //     <p style={{margin: '0 0 0 10px'}}>Pentacam PWR SF 4mm: </p>
  //     <p style={{margin: '0 0 0 10px'}}>Pentacam CT Min: </p>
  //       {
  //         props.lensTargetNear === true
  //           ? <p style={{margin: '0 0 0 10px'}}>Target -2.75 for near </p>
  //           : <p style={{margin: '0 0 0 10px'}}>Target -0.75 for distance </p>
  //         }
  //         </div>
  //       : ''
  
  //=====================================================================
  
  
  //=====================================================================
  let criFlacsAddOn = 
    props.cri === true
    &&
    props.flacs === true
    ? 'with Femtosecond Laser and Corneal Relaxing Incisions'
    : (
      props.cri === true
      &&
      props.flacs === false
    )
      ? 'with Corneal Relaxing Incisions'
      : (
        props.cri === false
        &&
        props.flacs === true
      )
        ? 'with Femtosecond Laser'
        : ''

  //=====================================================================
  

  const mapObj = {
    eyeSide: `${props.side}`,
    ptRefraction: `${ptRefraction}`,
    eyeBat: `20/${props.bat}`,
    bioAstigAxis: `${bioAstigAxis}`,
    chordMu: `${chordMu}`,
    topoAstigAxis: `${topoAstigAxis}`,
    topoSphAb: `${topoSphAb}`,
    topoHoa: `${topoHoa}`,
    lensModel: `${props.lensModel}`,
    lensOption: `${props.lensOption}`,
    lensTarget: `${lensTarget}`,
    lensPhysicianFee: `${props.lensPhysicianFee}`,
    lensHospitalFee: `${props.lensHospitalFee}`,
    lensFlacsFee: `${lensFlacsFee}`,
    lensCriFee: `${lensCriFee}`,
    oopTotal: `${props.oopTotal}`,
    oopPhysician: `${props.oopPhysician}`,
    oopHospital: `${props.oopHospital}`,
    procedureName: `${procedureName}`,
    dxNameAndCodes: `${dxNameAndCodes}`,
    dxAddOns: `${dxAddOns}`,
    criFlacsAddOn: `${criFlacsAddOn}`,
    icdTen: `${icdTen}`,
    ptContraindications: `${ptContraindications}`,
    bewareOfMyope: `${bewareOfMyope}`,
    lensAndTarget: `${lensAndTarget}`,
    nearTargetMrx: `${nearTargetMrx}`,
    sulcusLens: `${sulcusLens}`,
    ptAllergies: `${ptAllergies}`,
    codeStatus: `${codeStatus}`,
    toricAlignment: `${toricAlignment}`,
    lensesOffered: `Lenses offered to patient: ${lensesOffered}`,
    trialRx: `${trialRx}`,
    equipmentPreferenceAxis: `${equipmentPreferenceAxis}`,
  
    
  






    // cataractAddOn: 'going \n to the store',
    
  };

  // console.log(props.lensCriFee)
 

  // const propReplaced = str.replace(/\b(?:eyeSide|lensModel|lensOption|lensTarget|lensPhysicianFee|lensHospitalFee|lensFlacsFee|lensCriFee|oopTotal|oopPhysician|oopHospital|icdModifier|lensModel|lensOption|cataractAddOn)\b/gi, matched => mapObj[matched]);

  const propReplaced = str.replace(/(?:eyeSide|eyeBat|lensModel|lensOption|lensTarget|lensesOffered|trialRx|lensPhysicianFee|lensHospitalFee|lensFlacsFee|lensCriFee|oopTotal|oopPhysician|oopHospital|procedureName|dxNameAndCodes|icdTen|ptContraindications|bewareOfMyope|lensAndTarget|nearTargetMrx|sulcusLens|ptAllergies|codeStatus|toricAlignment|equipmentPreferenceAxis|ptRefraction|bioAstigAxis|chordMu|topoAstigAxis|topoSphAb|topoHoa|dxAddOns|criFlacsAddOn)/gi, matched => mapObj[matched]);
    
  const convertedData = convertFromRaw(JSON.parse(propReplaced))

  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertedData))

  let contentState = editorState.getCurrentContent();

  // console.log(convertToRaw(contentState));


  //Custom overrides for blockstyles
  const getBlockStyle = (block) => {
    switch (block.getType()) {
      case 'title': return 'RichEditor-title';
      case 'header': return 'RichEditor-header';
      case 'paragraph': return 'RichEditor-paragraph';
      case 'small-font': return 'RichEditor-small-font';
      case 'watermark': return 'RichEditor-watermark';
      default: return null;
    }
  }

  // Custom overrides for "code" style.
  const styleMap = {
    bolded: {
      fontWeight: '600'
    }
  };


  return (
    <div style={{display: 'flex'}}>
      <div style={{width: '7vw'}}></div>
      <div
        className='RichEditor-root'
        style={{
          width: '800px',
        }}
        >
        <div
          className='RichEditor-editor'
          style={{
            cursor: 'text',
            marginTop: '40px',
          }}
          >
          {
            props.bedsheetSplit === 'true'
            ? props.side === 'RIGHT'
              ? 
                <div style={{display: 'flex'}}>
                  <div
                    style={{minWidth: '50%'}}
                  >
                    <Editor
                      blockStyleFn={getBlockStyle}
                      customStyleMap={styleMap}
                      editorState={editorState}
                      onChange={editorState => {setEditorState(editorState)}}
                      readOnly={true}
                      />
                  </div>
                  <div 
                    style={{
                      minWidth: '50%',
                      borderLeft: "1px solid #292929",
                    }}>
                  </div>
                </div>
              : 
                <div style={{display: 'flex'}}>
                  <div 
                    style={{
                      minWidth: '50%',
                      borderRight: "1px solid #292929",
                    }}>
                  </div>
                  <Editor
                    blockStyleFn={getBlockStyle}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    onChange={editorState => {setEditorState(editorState)}}
                    readOnly={true}
                    />
                </div>
            : 
              <Editor
                blockStyleFn={getBlockStyle}
                customStyleMap={styleMap}
                editorState={editorState}
                onChange={editorState => {setEditorState(editorState)}}
                readOnly={true}
                />

          }
        </div>
       
      </div>
      <div style={{width: '7vw'}}></div>
    </div>
  );
}



// <p style={{margin: '0 0 30px 0'}}>{props.fName} {props.lName} • {props.dob}</p>