import React, { useState, useEffect, useRef } from 'react';

import LensPicker from './LensPickerFocus.js'
import BackArrow from '../partials/BackArrow.jsx'
import NearPicker from "./NearPickerFocus"
import NightSimulator from './NightSimulatorFocus.js';
import snellenChart from "./images/snellenChart.jpeg"
import nearImage from "./images/nearImage.png"

// import { FaCheck } from "react-icons/fa";
// import { MdDoNotDisturbAlt } from "react-icons/md";

// import api from "../services/api";
// import PrintPage from '../partials/PrintPage';

export default function VisionSimulator(props){


  //Create phakic vision variables that come from patient info
    //phakicPower
    let p = (props.power.toLowerCase() === 'plano' ||
      props.power.toLowerCase() === 'pl' ||
      props.power === '')
        ? 0
        : props.power*1;

    //phakicCyl
    let c = (props.cylinder.toLowerCase() === 'sphere' ||
      props.cylinder.toLowerCase() === 'sph' ||
      props.cylinder === '')
        ? 0
        : props.cylinder*0.5;

    //phakicAdd
    let a = props.add*1;



    //First, get lens data for lens that matches lensOption -->
    let currentLens = [];
    if (props.lensData && Array.isArray(props.lensData)) {
      currentLens = props.lensData.filter(lens => (
        lens.lens_name === props.lensOption
      ));
    }
      // console.log(currentLens)

// need to make it so that when it changes to current vision, these things are set back to nothing
    //Then, create variables to store lens data
    useEffect(() => {
      if(currentLens.length > 0){
          props.setLensName(currentLens[0].lens_name)
          props.setLensModel(currentLens[0].model_number)
          props.setLensLocation(currentLens[0].lens_location)
          props.setLensHospitalFee(currentLens[0].hospital_lens_fee)
          props.setLensPhysicianFee(currentLens[0].physician_lens_fee)
          props.setLensSphAb(currentLens[0].lens_sph_ab)
          props.setLensKSphAbResidual(currentLens[0].k_sph_ab_residual)
          props.setLensCorrectsIntermediateVision(currentLens[0].corrects_intermediate_vision)
          props.setLensCorrectsNearVision(currentLens[0].corrects_near_vision)
          props.setLensGlare(currentLens[0].lens_glare)
          props.setLensTopographerHoaCutoff(currentLens[0].lens_hoa_max)
          props.setLensKAstigMax(currentLens[0].lens_astig_max)
          props.setLensKAstigMin(currentLens[0].lens_astig_min)
          props.setLensKAstigResidual(currentLens[0].lens_astig_residual)
          props.setLensChordMu(currentLens[0].chord_mu_max)
          props.setLensBiometerStdDev(currentLens[0].biometer_std_dev_max)
          props.setLensBiometerTopographerAstigCutoff(currentLens[0].biometer_topographer_astig_max)
          props.setLensBiometerTopographerAxisCutoff(currentLens[0].biometer_topographer_axis_max)
          props.setLensCriMinAstig(currentLens[0].cri_min_astig)
          props.setLensCriMaxAstig(currentLens[0].cri_max_astig)
          props.setLensCriRequire(currentLens[0].cri_require)
          props.setLensFlacsRequire(currentLens[0].flacs_require)
          props.setLensConsentId(currentLens[0].consents)
          props.setLensCriFee(currentLens[0].cri_fee)
          props.setLensFlacsFee(currentLens[0].flacs_fee)
        } else {
          props.setLensName('')
          props.setLensModel('')
          props.setLensLocation('')
          props.setLensHospitalFee('')
          props.setLensPhysicianFee('')
          props.setLensSphAb('')
          props.setLensKSphAbResidual('')
          props.setLensCorrectsIntermediateVision('')
          props.setLensCorrectsNearVision('')
          props.setLensGlare('')
          props.setLensTopographerHoaCutoff('')
          props.setLensKAstigMax('')
          props.setLensKAstigMin('')
          props.setLensKAstigResidual('')
          props.setLensChordMu('')
          props.setLensBiometerStdDev('')
          props.setLensBiometerTopographerAstigCutoff('')
          props.setLensBiometerTopographerAxisCutoff('')
          props.setLensCriMinAstig('')
          props.setLensCriMaxAstig('')
          props.setLensCriRequire('')
          props.setLensFlacsRequire('')
          props.setLensConsentId('')
          props.setLensCriFee('')
          props.setLensFlacsFee('')
        }
    }, [currentLens[0]]);


    //Don't need to know how much cylinder to correct bc lens picker filters this
    let lensCorrectsAstigmatism = currentLens.length > 0
      ? (currentLens[0].lens_astig_min*1 === 0 || currentLens[0].lens_astig_min === 'dontuse')
        ? 'No'
        : currentLens[0].lens_astig_min*1 > 0
          ? 'Yes'
          : ''
      : ''
      // console.log(lensCorrectsAstigmatism)

    //Final Power
    //Lens set for distance? --> adjust postop distance blur to 0
    let finalPower = (props.lensOption === "Cataract vision" || props.lensOption === '')
      ? p+c
      //Lens set for near? --> adjust postop distance blur to -2.00
      : (props.lensTargetNear === true)
        ? -2.00
        : 0
        // console.log(finalPower + " final power")


    //Final Add
    let finalAdd = (props.lensCorrectsIntermediateVision === "Yes" && props.lensCorrectsNearVision === "Yes")
      //finalAdd should be 0 for lenses that correct intermediate and near
      ? 0
      : (props.lensCorrectsIntermediateVision === "Yes" && props.lensCorrectsNearVision === "No")
        //finalAdd should be 1 for lenses that correct intermediate but not near
        ? +1.00
        : (props.lensOption === "Cataract vision" || props.lensOption === '')
          //finalAdd should be patient's measured preop add if they haven't had surgery
          ? a
          : +2.50;
          // console.log(finalAdd + " final add")
          // console.log(a + " a")

    let distanceBlur =
      ((finalPower*1) > 0 && (finalPower*1)+(finalAdd*1-2.50) < 0)
      //if patient is hyperope preop but can accommodate, then there is no distance blur.  NB: this doesn't truly represent how a young hyperope might see well at distance without glasses
        ? 0
        : (finalPower*1) > 0
          //If patient is a hyperope preop and cannot accommodate enought to correct, then distance blur is difference between hyperopic power and accommodative power.
          ? (finalPower*1)+(finalAdd*1-2.50)
          //Otherwise, patient is plano or myopic (preop or postop) and you just set distance blur equal to finalPower (using absolute power to facilitate blur function)
          : Math.abs(finalPower*1);
          // console.log(distanceBlur + " distance blur")


    let intermediateBlur =
      ((finalPower*1+1.50) > 0 && ((finalPower*1+1.50)+(finalAdd*1-2.50)) <= 0)
        //If patient is more hyperopic than a -1.50 myope preop but can accommodate enough to see computer range, then intermediate blur is 0.  NB: Computer range requires +1.50 more of accommodative power than distance
        ? 0
        : (finalPower*1+1.50) > 0
          //If patient is more hyperopic than a -1.50 myope preop and cannot accommodate enought to correct for computer range (requires +1.50 more diopters of accommodation than distance vision), then intermediate blur is difference between (finalPower+1.50) and accommodative power.
          ? ((finalPower*1+1.50)+(finalAdd*1-2.50))
          //Otherwise, patient is a -1.50 myope or more myopic (preop or postop) and you just set intermediate blur equal to finalPower+1.50 (using absolute power to facilitate blur function)
          : Math.abs(finalPower*1+1.50);
          // console.log(intermediateBlur + " intermediate blur")


    let nearBlur =
      ((finalPower*1+2.50) > 0 && ((finalPower*1+2.50)+(finalAdd*1-2.50)) <= 0)
        //If patient is more hyperopic than a -2.50 myope preop but can accommodate enough to see near range, then near blur is 0.  NB: Near range requires +2.50 more of accommodative power than distance
        ? 0
        : (finalPower*1+2.50) > 0
          //If patient is more hyperopic than a -2.50 myope preop and cannot accommodate enought to correct for near range (requires +2.50 more diopters of accommodation than distance vision), then near blur is difference between (finalPower+2.50) and accommodative power.
          ? ((finalPower*1+2.50)+(finalAdd*1-2.50))
          : (finalPower*1 <= -2.50 && finalPower*1 >= -4.00)
          //If patient is between -2.50 and -4.00, there is no subjective blur.
            ? 0
            //Otherwise, patient is more than a -4.00 myope (preop or postop) and you just set near blur equal to finalPower+4 (using absolute power to facilitate blur function and +3.50 so that you get enough blur)
            : Math.abs(finalPower*1+4.00);
            // console.log(nearBlur + " near blur")


    //make bcva make vision more blurry as well as prescription
    let cataractBlur =
      (props.lensOption === "Cataract vision" || props.lensOption === '')
      ? props.bcva === 'CF'
        ? Math.sqrt((800/20)-1)*3
        : props.bcva === 'HM'
        ? Math.sqrt((2000/20)-1)*3
        : Math.sqrt(((props.bcva*1)/20)-1)*3
        : 0;
      // console.log(cataractBlur + " cataract blur")

    //make select for 0, 1+, 2+, 3+, 4+ NS.  cataractColor the A in RGBA color.  Might have to overlay color on image.
    // let cataractColor =
    //   (props.lensOption === "Cataract vision" || props.lensOption === '')
    //   ? (props.ns/2)
    //   : 0;
    //
    //
    // let sepiaBlur = `
    //     ${(0.393 + 0.607 * [1 - cataractColor])} ${(0.769 - 0.769 * [1 - cataractColor])} ${(0.189 - 0.189 * [1 - cataractColor])} 0 0
    //     ${(0.349 - 0.349 * [1 - cataractColor])} ${(0.686 + 0.314 * [1 - cataractColor])} ${(0.168 - 0.168 * [1 - cataractColor])} 0 0
    //     ${(0.272 - 0.272 * [1 - cataractColor])} ${(0.534 - 0.534 * [1 - cataractColor])} ${(0.131 + 0.869 * [1 - cataractColor])} 0 0
    //     0 0 0 1 0
    //     `
        // console.log(sepiaBlur + " sepia blur")




    // Use equipmentPreference for astigmatism
    let bioAstig = 
    props.biometerAstig === '' ||
    props.biometerAstig === 'sph' ||
    props.biometerAstig === 'sphere' ||
    props.biometerAstig === ''
    ? 0
    : props.biometerAstig
    
    let topoAstig = 
    props.topographerAstig === '' ||
    props.topographerAstig === 'sph' ||
    props.topographerAstig === 'sphere' ||
    props.topographerAstig === ''
    ? 0
    : props.topographerAstig


    let combAstig =
      (topoAstig*1+bioAstig*1)/2
        // console.log(props.biometer)
        // console.log(props.topographer)
        // console.log(props.equipmentPreference)
        // console.log(equipmentPreference)
        // console.log(combAstig)


    // Use equipmentPreference for axis
    let equipmentPreferenceAxis =
      props.equipmentPreference === props.biometer
      ? props.biometerAxis
      : props.equipmentPreference === props.topographer
        ? props.topographerAxis
        : ''
        // console.log(props.biometer)
        // console.log(props.topographer)
        // console.log(props.equipmentPreference)
        // console.log(equipmentPreference)
        // console.log(equipmentPreferenceAxis)



    //  if preop, use props.cylinder.  if postop, use combAstig
    let cylAstigx =
      props.cylinder === '' ||
      props.cylinder === 'sph'||
      props.cylinder === 'sphere' ||
      props.axis === ''
      ? 0
      : (props.cylinder)*Math.cos(props.axis*(Math.PI/180))

    let cylAstigy =
      props.cylinder === '' ||
      props.cylinder === 'sph'||
      props.cylinder === 'sphere' ||
      props.axis === ''
      ? 0
      : (props.cylinder)*Math.sin(props.axis*(Math.PI/180))


    let astigmatismx  =
      props.lensOption === 'Cataract vision'
      ? cylAstigx
      : (
          lensCorrectsAstigmatism === 'Yes'
          &&
          currentLens[0].lens_astig_min*1 <= combAstig
          &&
          currentLens[0].lens_astig_max*1 >= combAstig
        )
        ||
        props.cri === true
        ? (combAstig - Math.min(props.topographerAstig, props.biometerAstig))*Math.cos(equipmentPreferenceAxis*(Math.PI/180))
        : (
            lensCorrectsAstigmatism === 'Yes'
            &&
            currentLens[0].lens_astig_min*1 <= combAstig
            &&
            currentLens[0].lens_astig_max*1 < combAstig
          )
          ? (combAstig-currentLens[0].lens_astig_max*1)*Math.cos(equipmentPreferenceAxis*(Math.PI/180))
          : combAstig*Math.cos(equipmentPreferenceAxis*(Math.PI/180))
        
          // console.log(astigmatismx + " x astigmatism")

      let astigmatismy  =
        props.lensOption === 'Cataract vision'
        ? cylAstigy
        : (
          lensCorrectsAstigmatism === 'Yes'
          &&
          currentLens[0].lens_astig_min*1 <= combAstig
          &&
          currentLens[0].lens_astig_max*1 >= combAstig
        )
        ||
        props.cri === true
        ? (combAstig - Math.min(props.topographerAstig, props.biometerAstig))*Math.sin(equipmentPreferenceAxis*(Math.PI/180))
        : (
            lensCorrectsAstigmatism === 'Yes'
            &&
            currentLens[0].lens_astig_min*1 <= combAstig
            &&
            currentLens[0].lens_astig_max*1 < combAstig
          )
          ? (combAstig-currentLens[0].lens_astig_max*1)*Math.sin(equipmentPreferenceAxis*(Math.PI/180))
          : combAstig*Math.sin(equipmentPreferenceAxis*(Math.PI/180))
      
          // console.log(astigmatismy + " y astigmatism")

      
    let equipmentPreferenceAstig = combAstig

    let biometerTopographerAstigDiff =
      Math.abs(props.biometerAstig*1 - props.topographerAstig*1)
      //console.log(biometerTopographerAstigDiff)

    // need to make input of 180 = 0 and input of '' not count
    let biometerTopographerAxisDiff;
    props.biometerAxis*1 >= 165 && props.topographerAxis*1 <= 15
      ? biometerTopographerAxisDiff = ((props.topographerAxis*1 + 180)-props.biometerAxis*1)
      : props.biometerAxis*1 <= 15 && props.topographerAxis*1 >= 165
        ? biometerTopographerAxisDiff = ((props.biometerAxis*1 + 180) - props.topographerAxis*1)
        : biometerTopographerAxisDiff = Math.abs(props.biometerAxis*1 - props.topographerAxis*1)

    // console.log(biometerTopographerAxisDiff)


    const ASPECT_RATIO = 1821 / 3600; // aspect ratio of height / width
    const [containerHeight, setContainerHeight] = useState(window.innerHeight);
    const [containerWidth, setContainerWidth] = useState(window.innerWidth);
    const containerRef = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        setContainerHeight(window.innerHeight);
        setContainerWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const aspectHeight = 
      (containerHeight / containerWidth) < ASPECT_RATIO
        ? containerHeight
        : (containerWidth * ASPECT_RATIO);

    const aspectWidth = 
      (containerHeight / containerWidth) > ASPECT_RATIO
        ? containerWidth
        : (containerHeight / ASPECT_RATIO);


    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])


    let reduceAstig = props.lensData.find((l) => 
      l.lens_name === props.lensOption)?.lens_astig_max || null;

    let nearVision = props.lensData.find((l) => 
      l.lens_name === props.lensOption)?.corrects_near_vision || null;
  
    let intermediateVision = props.lensData.find((l) => 
      l.lens_name === props.lensOption)?.corrects_intermediate_vision || null;


    // console.log(reduceAstig)




    //Paralax scrolling for images===============================
    // const [scrollY, setScrollY] = useState(0);

    // useEffect(() => {
    //   const handleScroll = () => {
    //     setScrollY(window.scrollY);
    //   };
  
    //   window.addEventListener('scroll', handleScroll);
  
    //   return () => {
    //     window.removeEventListener('scroll', handleScroll);
    //   };
    // }, []);
  
    // const calculateParallaxOffset = (layer, strength) => {
    //   return -(scrollY * strength) / 100;
    // };

   //==================================================
   const [flicker, setFlicker] = useState('transparent'); // Track flickering state
   const [dysphotopsiaStyle, setDysphotopsiaStyle] = useState(false); // Track flickering state
   const moonRef = useRef(null);

   const handleDysphotopsia = () => {
      setDysphotopsiaStyle(true)

      // Set timeouts to handle the flicker effect
      const timeout1 = setTimeout(() => {
        setFlicker('gray'); // Change color after the first flicker
      }, 100); // After 0.1 seconds

      const timeout2 = setTimeout(() => {
        setFlicker('transparent'); // Change color back to original
      }, 200); // After 0.2 seconds

      const timeout3 = setTimeout(() => {
        setFlicker('gray'); // Change color to gray
      }, 500); // After 0.5 seconds

      const timeout4 = setTimeout(() => {
        setFlicker('transparent'); // Change color back to transparent
      }, 600); // After 0.6 seconds

      const timeout5 = setTimeout(() => {
        setFlicker('gray'); // Change color to gray
      }, 1200); // After 1.2 seconds

      const timeout6 = setTimeout(() => {
        setFlicker('transparent'); // Change color back to transparent
      }, 1300); // After 1.3 seconds

      // Set another timeout to turn off dysphotopsiaStyle after all flickering is done
      const timeout7 = setTimeout(() => {
        setDysphotopsiaStyle(false); // Stop the effect after the last flicker
      }, 2000); // Matches the last flicker timing

      // Clean up the timeouts on component unmount 
      return () => {
        clearTimeout(timeout1);
        clearTimeout(timeout2);
        clearTimeout(timeout3);
        clearTimeout(timeout4);
        clearTimeout(timeout5);
        clearTimeout(timeout6);
        clearTimeout(timeout7);
      };
    }; 


    let rightFlickerStyle = {
      width: '20%', // 20% of the container's width
      position: 'absolute',
      zIndex: '4',
      bottom: `0px`,
      left: 0.4*aspectHeight, // Changed from right to left
      height: aspectHeight,
      borderRadius: '50%', // Creates a circle
      backgroundColor: 'transparent', // Makes the background transparent
      boxShadow: `inset -10px 0px 0px 0px ${flicker}`, // Changed the direction of the shadow to make the crescent open to the left
      MozBoxShadow: `inset -10px 0px 0px 10px ${flicker}`, // Firefox fix for the shadow
      opacity: "0.5"
      
    }

    let leftFlickerStyle = {
      width: '20%', // 20% of the container's width
      position: 'absolute',
      zIndex: '4',
      bottom: `0px`,
      left: -0.15*aspectHeight,
      height: aspectHeight,
      borderRadius: '50%', // Creates a circle
      backgroundColor: 'transparent', // Makes the background transparent
      boxShadow: `inset 10px 0px 0px 0px ${flicker}`, // Creates the crescent shadow with white color
      MozBoxShadow: `inset 10px 0px 0px 10px ${flicker}`, // Firefox fix for the shadow
      opacity: "0.5"
    }


    let faStyle = {
      color: '#4CBB17',
      fontSize: '30px',
      margin: '0 0 0px 0',
    }

    let noStyle = {
      color: 'red',
      fontSize: '30px',
      margin: '0 0 0px 0',
    }

    let detailStyle = {
      // display: 'flex',
      fontSize: '16px',
    }

    let financingStyleFalse = {
      margin: '30px 0 0 0',
      padding: '0 0 10px 0',
      color: '#444',
      width: '50%',
      textAlign: 'center',
      cursor: 'pointer',
    }
    
    let financingStyleTrue = {
      margin: '30px 0 0 0',
      padding: '0 0 10px 0',
      color: '#292929',
      borderBottom: '2px solid #292929',
      weight: '600',
      width: '50%',
      textAlign: 'center',
      cursor: 'pointer',
    }


    let dysphotopsiaButtonStyleFalse = {
        border: '3px solid #7D7E7F',
        padding: '3px 8px',
        margin: '2px 2px -8px 2px',
        fontSize: '18px',
        color: '#7D7E7F',
        width: '126px',
        height: '22px',
        borderRadius: '5px',
        background: '#fff',
        textAlign:'center',
        position: 'absolute',
        left: 0.1*aspectHeight,
        bottom: '-50px',
        zIndex: '5',
        cursor: 'pointer',
      }
    
      let dysphotopsiaButtonStyleTrue = {
        border: '3px solid #4CBB17',
        padding: '3px 8px',
        margin: '2px 2px -8px 2px',
        fontSize: '18px',
        fontWeight: '600',
        color: '#4CBB17',
        width: '126px',
        height: '22px',
        borderRadius: '5px',
        background: '#FFF',
        textAlign:'center',
        position: 'absolute',
        left: 0.1*aspectHeight,
        bottom: '-50px',
        zIndex: '5',
        cursor: 'pointer',
      }
    
      
 

   //==================================================
    
  return( 
    <div style={{
      width: '100vw',
      background: "#FFF",
      padding: '0 0 150px 0',
      }}>
      {
          props.userType === 'qrpt'
          ? <BackArrow
              linkTo='/tutorial4'
            />
          : props.userType === 'qrmd'
          ? 
            <BackArrow
              linkTo='/patientinfo'
            />
          : 
          (
            props.userType === 'md' 
            &&
            props.useTutorial === 'true'
          )
          ? 
            <BackArrow
              linkTo='/tutorial3'
            />
          : 
          (
            props.userType === 'md' 
            &&
            props.useTutorial === 'false'
            &&
            props.useVideo === 'true'
          )
          ? 
            <BackArrow
              linkTo='/tutorialvideo'
            />
          :
          (
            props.userType === 'md' 
            &&
            props.useTutorial === 'false'
            &&
            props.useVideo === 'false'
          )
          ? 
            <BackArrow
              linkTo='/patientinfo'
            />
          : <BackArrow
              linkTo='/'
            />
        }

      
      <div style={{display: 'flex'}}>
        <div style={{width: '7vw'}}></div>

            
          <div style={{display: 'flex', justifyContent: 'space-evenly', width: '88vw'}}>
            
            <div style={{
              display: 'flex', 
              justifyContent: 'center', 
              width: '70%',
              padding: '10px',
              margin: '40px 0 40px 0',
            }}>
              <div>
                <p style={{
                  textAlign: 'center',
                  fontSize: '36px',
                  fontWeight: '600',
                  color: '#292929',
                  width: '100%',
                  margin: '0px 0 120px 0',
                }}>
                  Simulated Day & Night vision
                </p>
                <div style={{width: aspectHeight*0.6}}>
                  
                  <div 
                    style={{
                      position: 'relative', 
                      width: '100%', 
                      height: 0.7*aspectHeight,
                      margin: '80px 0 0 0',
                    }}
                  >
                    
                    <img
                      className='snellenChart'
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        bottom: `${astigmatismy*3}px`,
                        left: `${0.2*aspectHeight+astigmatismx*3}px`,
                        maxHeight: aspectHeight*0.8,
                        opacity: '28%',
                        filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                        // transform: `translate3d(0, ${calculateParallaxOffset('near', 0)}px, 0)`,
                      }}
                      src={snellenChart}
                      alt="Snellen"
                    />
                    <img
                      className='snellenChart'
                      style={{
                        position: 'absolute',
                        zIndex: '1',
                        bottom: `${-astigmatismy*3}px`,
                        left:`${0.2*aspectHeight-astigmatismx*3}px`,
                        maxHeight: aspectHeight*0.8,
                        opacity: '28%',
                        filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                        // transform: `translate3d(0, ${calculateParallaxOffset('near', 0)}px, 0)`,
                      }}
                      src={snellenChart}
                      alt="Snellen"
                    />

                    <img
                      className='snellenChart'
                      style={{
                        position: 'absolute',
                        zIndex: '0',
                        bottom: '0px',
                        left: 0.2*aspectHeight,
                        maxHeight: aspectHeight*0.8,
                        opacity: '100%',
                        filter: `blur(${distanceBlur*1.5 + cataractBlur*1}px)`,
                      }}
                      src={snellenChart}
                      alt="Snellen"
                    />

                    <img
                        className='nearImage'
                        style={{
                          position: 'absolute',
                          zIndex: '3',
                          bottom: `${-astigmatismy*2}px`,
                          left:`${-0.1*aspectHeight-astigmatismx*2}px`,
                          maxHeight: aspectHeight*0.45,
                          opacity:'28%',
                          filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                          // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                        }}
                        src={nearImage}
                        alt="Near"
                        />

                      <img
                        className='nearImage'
                        style={{
                          position: 'absolute',
                          zIndex: '3',
                          bottom: `${astigmatismy*2}px`,
                          left:`${-0.1*aspectHeight+astigmatismx*2}px`,
                          maxHeight: aspectHeight*0.45,
                          opacity:'28%',
                          filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                          // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                        }}
                        src={nearImage}
                        alt="Near"
                        />

                      <img
                        className='nearImage'
                        style={{
                          position: 'absolute',
                          zIndex: '2',
                          bottom: '0px',
                          left: -0.1*aspectHeight,
                          maxHeight: aspectHeight*0.45,
                          opacity:'100%',
                          filter: `blur(${(nearBlur*2.5 + cataractBlur*1)*0.6}px)`,
                          // transform: `translate3d(0, ${calculateParallaxOffset('near', 10)}px, 0)`
                        }}
                        src={nearImage}
                        alt="Near"
                      />
                      <NightSimulator
                        astigmatismx={astigmatismx}
                        astigmatismy={astigmatismy}
                        intermediateBlur={intermediateBlur}
                        distanceBlur={distanceBlur}
                        cataractBlur={cataractBlur}
                        lensCorrectsAstigmatism={lensCorrectsAstigmatism}
                        lensCorrectsNearVision={props.lensCorrectsNearVision}
                        lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                        lensGlare={props.lensGlare}
                        lensOption={props.lensOption}
                        ns={props.ns}
                        lensData={props.lensData}
                        aspectHeight={aspectHeight}
                        aspectWidth={aspectWidth}
                        equipmentPreferenceAstig={equipmentPreferenceAstig}
                        equipmentPreferenceAxis={equipmentPreferenceAxis}
                      />

                      <div 
                        onClick={() => handleDysphotopsia()}
                        style={
                          dysphotopsiaStyle === true
                          ? dysphotopsiaButtonStyleTrue
                          : dysphotopsiaButtonStyleFalse
                        } 
                      >
                          Dysphotopsia
                      </div>
                      
                      <NearPicker
                        lensCorrectsNearVision={props.lensCorrectsNearVision}
                        lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                        lensOption={props.lensOption}
                        lensTargetNear={props.lensTargetNear}
                        setLensTargetNear={props.setLensTargetNear}
                      />

                      <div
                        style={{
                          position: 'absolute',
                          zIndex: '4',
                          bottom: `-20px`,
                          left: -0.1*aspectHeight,
                          width: 1.1*aspectHeight,
                          height: 0.9*aspectHeight,
                          opacity:
                            props.lensOption === 'Cataract vision'
                            ? `${props.ns*15}%`
                            : '0%',
                          borderRadius: '20px',
                          background: '#967724',
                        }}
                        >
                      </div>
                      <div style= {{width: '100%'}}>
                        <div 
                          ref={moonRef}
                          style={props.side === 'RIGHT'
                            ? rightFlickerStyle
                            : leftFlickerStyle
                          }
                        >
                        </div>
                      </div>


                  

                  </div>
                </div>
              </div>            
            </div>
            <div
              style={{
                minWidth: '342px',
                maxWidth: '342px',
                padding: '10px',
                margin: '40px 0 40px 0',
              }}
            >
              <p style={{
                 textAlign: 'center',
                 fontSize: '36px',
                 fontWeight: '600',
                 color: '#292929',
                 width: '100%',
                 margin: '0px 0 30px 0',
              }}>
                Lens Options
              </p>

              <div>
                <div 
                  style={{
                    display:'flex',
                    width: '100%',
                  }}
                >
                  <p
                    onClick={() => props.setFinancing(false)}
                    style={
                      props.financing === true
                      ? financingStyleFalse
                      : financingStyleTrue
                    }
                  >
                    Cash
                  </p>
                  <p
                    onClick={() => props.setFinancing(true)}
                    style={
                      props.financing === true
                      ? financingStyleTrue
                      : financingStyleFalse
                    }
                  >
                    Finance
                  </p>
                </div>
                <hr
                  style={{
                    border: '0.5px solid lightgray',
                    margin: '0',
                  }}
                />
             
              <LensPicker
                biometerTopographerAstigDiff={biometerTopographerAstigDiff}
                biometerTopographerAxisDiff={biometerTopographerAxisDiff}
                lensData={props.lensData}
                choseBestLens={props.choseBestLens}
                setChoseBestLens={props.setChoseBestLens}
                lensOption={props.lensOption}
                setLensOption={props.setLensOption}
                lensHospitalFee={props.lensHospitalFee}
                setLensHospFee={props.setLensHospFee}
                lensPhysicianFee={props.lensPhysicianFee}
                setLensPhysFee={props.setLensPhysFee}
                lensSphAb={props.lensSphAb}
                contraindications={props.contraindications}
                biometer={props.biometer}
                biometerAstig={props.biometerAstig}
                biometerAxis={props.biometerAxis}
                biometerStdDev={props.biometerStdDev}
                chordMuTotal={props.chordMuTotal}
                topographer={props.topographer}
                topographerAstig={props.topographerAstig}
                topographerAxis={props.topographerAxis}
                topographerSphAb={props.topographerSphAb}
                topographerHoa={props.topographerHoa}
                sphAbTarget={props.sphAbTarget}
                hoaMaxPreference={props.hoaMaxPreference}
                equipmentPreference={props.equipmentPreference}
                physicianName={props.physicianName}
                lensCorrectsNearVision={props.lensCorrectsNearVision}
                lensCorrectsIntermediateVision={props.lensCorrectsIntermediateVision}
                lensTopographerHoaCutoff={props.lensTopographerHoaCutoff}
                lensTargetNear={props.lensTargetNear}
                setLensTargetNear={props.setLensTargetNear}
                lensKAstigMin={props.lensKAstigMin}
                lensKAstigMax={props.lensKAstigMax}
                lensKAstigResidual={props.lensKAstigResidual}
                lensBiometerTopographerAstigCutoff={props.lensBiometerTopographerAstigCutoff}
                lensBiometerTopographerAxisCutoff={props.lensBiometerTopographerAxisCutoff}
                equipmentPreferenceAstig={equipmentPreferenceAstig}

                lensCriMinAstig={props.lensCriMinAstig}
                lensCriMaxAstig={props.lensCriMaxAstig}
                lensCriRequire={props.lensCriRequire}
                lensCriFee={props.lensCriFee}
                lensFlacsFee={props.lensFlacsFee}
                cri={props.cri}
                setCri={props.setCri}
                criOffer={props.criOffer}
                criAlwaysOn={props.criAlwaysOn}
                setCriAlwaysOn={props.setCriAlwaysOn}
                criAllowToggle={props.criAllowToggle}
                setCriAllowToggle={props.setCriAllowToggle}
                criBioTopoAstigMax={props.criBioTopoAstigMax}
                criBioTopoAxisMax={props.criBioTopoAxisMax}

                flacs={props.flacs}
                setFlacs={props.setFlacs}
                flacsOffer={props.flacsOffer}
                lensFlacsRequire={props.lensFlacsRequire}
                flacsAlwaysOn={props.flacsAlwaysOn}
                setFlacsAlwaysOn={props.setFlacsAlwaysOn}
                flacsAllowToggle={props.flacsAllowToggle}
                setFlacsAllowToggle={props.setFlacsAllowToggle}

                oopTotal={props.oopTotal}
                setOopTotal={props.setOopTotal}
                oopPhysician={props.oopPhysician}
                setOopPhysician={props.setOopPhysician}
                oopHospital={props.oopHospital}
                setOopHospital={props.setOopHospital}

                financing={props.financing}
              
                setPtLensList={props.setPtLensList}
                selectedColor={props.selectedColor}
                setSelectedColor={props.setSelectedColor}
                staticLensListOptions={props.lensData}
                
                onlyCompatible={props.onlyCompatible}
                setOnlyCompatible={props.setOnlyCompatible}
              />
            </div>
            </div> 
            
          </div>
        <div style={{width: '3vw'}}></div>
      </div>
      
      </div>

  )
}


// nightGlare image reference = https://www.ophthalmologytimes.com/view/match-trifocal-iol-patient-needs-preferences-best-results




// <div 
//   style={{
//     display:'flex',
//     justifyContent: 'space-between',
//   }}
// >
//   {
//     <div style={detailStyle}>
//       <div 
//         style={{
//           width:'100%',
//           display:'flex',
//           justifyContent: 'center',
//         }}
//       >
//         {
//             (reduceAstig*1 > 0
//             ||
//             props.cri === true)
//             ? <FaCheck style={faStyle}/>
//             : <MdDoNotDisturbAlt style={noStyle}/>
//         }  
//       </div>
//       <div style={{width:'100%', textAlign: 'center'}}>
//         Reduced astigmatism
//       </div>
//     </div>
//   }
//   <div style={detailStyle}>
//     <div 
//       style={{
//         width:'100%',
//         display:'flex',
//         justifyContent: 'center',
//       }}
//     >
//       {
//           intermediateVision === 'Yes'
//           ? <FaCheck style={faStyle}/>
//           : <MdDoNotDisturbAlt style={noStyle}/>
//       }
//     </div>
//     <div style={{width:'100%', textAlign: 'center'}}>
//       Intermediate vision
//     </div>
//   </div>
//   <div style={detailStyle}>
//     <div 
//       style={{
//         width:'100%',
//         display:'flex',
//         justifyContent: 'center',
//       }}
//     >
//       {
//           nearVision === 'Yes'
//           ? <FaCheck style={faStyle}/>
//           : <MdDoNotDisturbAlt style={noStyle}/>
//       }
//     </div>
//     <div style={{width:'100%', textAlign: 'center'}}>
//       Near vision
//     </div>
//   </div>
// </div>



// <div style={{ display: 'flex', flexDirection: 'column'}}>
//               <div
//                 style={{
//                   height: aspectHeight,
//                   width: aspectWidth,
//                   position: 'relative',
//                   margin: "0 auto",
//                 }}
//                 ref={containerRef}
//                 >
//                   <p
//                     style={{
//                       color: '#FFF',
//                       textAlign: 'center',
//                       padding: '10px 0 10px 0',
//                       margin: "0",
//                       background: '#292929',
//                       zIndex: '6',
//                       position: "absolute",
//                       left: "50%",
//                       top: aspectHeight,
//                       width: '100vw',
//                       transform: 'translateX(-50%)',
//                     }}
//                   >
//                     Simulation: results may vary
//                   </p>
//               </div>
//             </div>